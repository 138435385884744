'use client';
import { useEffect, useState } from 'react';
import { FormPopup } from './FormPopup';
import { useGlobalStore } from '@/app/stores/useGlobalStore';

export default function DelayedFormPopup({ slug }: { slug?: string }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [, setShowToast] = useState(false);
  const { hasShownPopup } = useGlobalStore();
  const ctaValue = hasShownPopup ? 'HOMEPAGE-HEADER-BUTTON' : 'AUTO-POPUP-FORM';

  useEffect(() => {
    if (!slug?.toLowerCase().includes('sex') && !hasShownPopup) {
      const timer = setTimeout(() => {
        setOpenPopup(true);
      }, 9000);

      return () => clearTimeout(timer);
    }
  }, [slug, hasShownPopup]);

  return (
    <FormPopup
      ctaValue={ctaValue}
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      setShowToast={setShowToast}
      showTreatment={true}
    />
  );
}
